<template>

    <v-card class="rounded-xl d-flex flex-column"
            :height="$vuetify.breakpoint.xs ? '' :  800"
            max-height="100%"
            style="overflow-y: hidden"

    >
      <v-card-title>
        <h2 class="px-6 py-4 tit">Afegir assignatures</h2>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          height="50"
          solo
          flat
          hide-details
          rounded
          background-color="rgb(245 245 245)"
          :append-icon="mdiMagnify"
          label="Busca qualsevol assignatura..."
        ></v-text-field>
        <v-spacer></v-spacer>


        <v-icon @click="addDialog=false">{{ mdiClose }}</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-sheet color="grey lighten-4" width="100%" height="100%"
               min-height="600"
               style="overflow-y: auto"
               class="flex-grow-1">


        <!--========================================-->
        <!-- Llista Assignatures                    -->
        <!--========================================-->
        <div v-if="subjectsFilter" style="min-height: 500px" class="mt-10">
          <v-slide-x-transition
            v-for="subject in subjectsFilter" cols="12" md="4"
          >
            <v-card
              :style="isSubjectSelected(subject.subject_id) ? 'border-left: 7px solid #256EFF' : ''"
              class="rounded-lg shadow-sm d-flex align-center mb-4 mx-12"
              height="50"
            >
              <v-icon style="min-width: 70px">
                {{ $vuetify.icons.values[subject.subject_icon] }}

              </v-icon>
              <div class="align-center d-flex flex-grow-1 pr-3">
                <p class="mb-0 grey--text text--darken-2 text--darken-4 grey--text">{{ subject.subject_name }}</p>
                <v-spacer></v-spacer>
                <v-hover v-if="isSubjectSelected(subject.subject_id)"
                         v-slot:default="{ hover }">
                  <v-btn
                    @click="deleteSubject(subject.subject_id)"
                    :color="hover ? 'red' : ''"
                    icon>
                    <v-icon>{{ mdiBookMinus }}</v-icon>
                  </v-btn>
                </v-hover>
                <v-hover v-else v-slot:default="{ hover }">
                  <v-btn
                    @click="addSubject(subject.subject_id)"
                    :color="hover ? 'success' : ''"
                    icon>
                    <v-icon>{{ mdiBookPlusOutline }}</v-icon>
                  </v-btn>
                </v-hover>
              </div>

            </v-card>
          </v-slide-x-transition>
        </div>
      </v-sheet>

    </v-card>
</template>

<script>
import {mdiMagnify } from '/src/assets/mdi.json';
import {mdiClose } from '/src/assets/mdi.json';
import {mdiBookMinus } from '/src/assets/mdi.json';
import {mdiBookPlusOutline } from '/src/assets/mdi.json';

export default {
  name: "AddMySubjectsDialog",
  mounted() {
    this.fetchSubjects()
  },
  props: {
    value: Boolean,
    mySubjects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mdiBookMinus: mdiBookMinus,
      mdiBookPlusOutline: mdiBookPlusOutline,
      mdiMagnify: mdiMagnify,
      mdiClose: mdiClose,
      allSubjects: [],
      search: ''
    }
  },
  computed: {
    addDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    subjectsFilter() {
      if (this.allSubjects.length > 0)
        return this.allSubjects.filter(sub => sub.subject_name.toLowerCase().includes(this.search))
      return []
    },

  },
  methods: {
    isSubjectSelected(id) {
      return !!this.mySubjects.find((s) => s.subject_id === id)
    },
    async fetchSubjects() {
      try {
        const {data} = await this.axios.get('/subjects')
        this.allSubjects = data;
      } catch (e) {
        throw e
      }
    },
    async addSubject(id) {
      try {
        await this.axios.post('/user-subject', {subject_id: id}, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.$emit('fetchMySubjects')
      } catch (e) {
        throw e
      }
    },
    async deleteSubject(id) {
      try {
        await this.axios.delete(`/user-subject/${id}`, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.$emit('fetchMySubjects')
      } catch (e) {
        throw e
      }
    },

  }
}
</script>

<style scoped>

</style>
