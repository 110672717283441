<template>
  <div>

    <!--========================================-->
    <!-- Les meves assignatures                 -->
    <!--========================================-->
    <v-hover class="transition-linear-out-slow-in"
             v-slot:default="{ hover }">
      <v-card class="pa-4 shadow-small rounded-lg ">

        <v-card-title class="px-0 py-0">
          <h2 class="title tit font-weight-bold ml-2 mt-2 mb-3" style="font-size: 25px !important;">
            Problemes guardats
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="addDialog=true"
            v-if="collections.length > 0 && hover"
            height="30"
            :ripple="false"
            icon
            class="ml-4 collections-edit-button ">
            <v-icon
            >
              {{ mdiPlus }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <!--========================================-->
        <!-- Afegir assignatures                    -->
        <!--========================================-->
        <div
          v-if="collections.length === 0"
          class="mt-4 ml-2">

          <v-btn
            color="primary"
            depressed
            @click="addDialog=true"
            :ripple="false"
            large
            class="text-none shadow-small rounded tit">
            <v-icon left>{{mdiPlus}}</v-icon>
            Afegir una col·lecció de problemes
          </v-btn>

          <p class="mt-2 mb-0">Encara no tens cap col·lecció...</p>
          <p>Utilitza les col·leccions per guardar problemes d'una forma classificada.</p>

        </div>


        <!--========================================-->
        <!-- Llista Assignatures                    -->
        <!--========================================-->
        <v-row v-else class="pa-2">
          <v-col v-for="collection in collections"
                 :key="collection.collection_id"
                 cols="6" md="3">
            <v-responsive aspect-ratio="1">

              <v-card
                @click="$emit('onClick', collection)"
                color="grey lighten-3"
                flat

                height="100%"
                width="100%"
                class="rounded-lg align-center pa-1"
              >
                <v-row v-if="collection.preview && collection.preview.length > 0" no-gutters>
                  <v-col cols="6" v-for="problem in collection.preview">
                    <v-responsive aspect-ratio="1">
                      <v-card color="transparent" tile height="100%" width="100%">
                        <v-img :src="getImage(problem)"></v-img>
                      </v-card>
                    </v-responsive>
                  </v-col>
                </v-row>
                <div v-else style="height: 100%; width: 100%" class="text-center d-flex flex-grow-1">
                  <v-icon large class="ma-auto">{{mdiBookmarkOffOutline}}</v-icon>
                </div>
              </v-card>
            </v-responsive>

            <p class="d-inline-block text-truncate title mb-0 mt-1"
               style="flex: 1">
              {{ collection.collection_name }}
            </p>
          </v-col>
        </v-row>


      </v-card>
    </v-hover>

    <!--========================================-->
    <!-- Nova Col·lecció Dialog                   -->
    <!--========================================-->
    <v-dialog v-model="addDialog" max-width="850"
              :fullscreen="$vuetify.breakpoint.xs"
              scrollable
              overlay-color="grey" persistent content-class="rounded-xl shadow-md">
      <v-card class="rounded-xl d-flex flex-column"
              max-height="100%"
              style="overflow-y: hidden"
      >
        <v-card-title class="py-0">
          <h2 class="px-6 py-4" style="font-size: 22px !important;">Nova col·lecció</h2>
          <v-spacer></v-spacer>
          <v-icon @click="addDialog=false">{{mdiClose}}</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="px-12 py-4"
          style="min-height: 300px; flex: 1; flex-direction: column; background-color: rgb(245 245 245)">

          <div class="my-auto py-12">
            <p class="mb-2 grey--text text--darken-2">Nom de la col·lecció</p>
            <v-text-field v-model="collectionName"
                          solo
                          flat
                          hide-details
                          class="shadow-small"
            ></v-text-field>
            <div class="d-flex mt-4">
              <v-spacer></v-spacer>
              <v-btn
                @click="createCollecion"
                color="primary"
                :loading="creatingLoader"
                depressed
                large
                class="text-none shadow-small rounded tit px-10">
                Crear
              </v-btn>
            </div>

          </div>

        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mdiPlus} from '/src/assets/mdi.json'
import {mdiBookmarkOffOutline} from '/src/assets/mdi.json'
import {mdiClose} from '/src/assets/mdi.json'

export default {
  name: "SavedProblems",
  data() {
    return {
      mdiPlus: mdiPlus,
      mdiBookmarkOffOutline: mdiBookmarkOffOutline,
      mdiClose: mdiClose,
      addDialog: false,
      creatingLoader: false,
      collections: [],
      collectionName: ''
    }
  },
  methods: {
    async fetchPreviewCollections(collection_id) {
      try {
        const {data} = await this.axios.get(`/problems/collections/${collection_id}/4`, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        return data;
      } catch (e) {
        throw e
      }
    },
    async createCollecion() {
      try {
        this.creatingLoader = true;
        await this.axios.post('/collections', {
          collection_name: this.collectionName
        }, {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        await this.fetchCollections()
      } catch (e) {
        throw e
      } finally {
        this.creatingLoader = false;
        this.addDialog = false;
      }
    },
    getImage(problem) {
      return `https://examenselectivitat.cat:3000/api/images/${problem.subject_path}/${problem.year}/${problem.serie}/${problem.problem_id}/problem`
    },
    async fetchCollections() {
      try {
        const {data} = await this.axios.get('/collections', {
          headers: {
            Authorization: this.$root.token.value
          }
        })
        this.collections = data;

        let idx = 0;
        for await (let col of this.collections) {
          const res = await this.fetchPreviewCollections(col.collection_id)
          this.collections[idx].preview = res;
          idx++;
        }

      } catch (e) {
        throw e
      }
    }
  },
  watch: {
    '$root.token': {
      immediate: true,
      async handler(val) {
        if (val)
          await this.fetchCollections();
      }
    }
  }
}
</script>

<style scoped>
.collections-edit-button >>> .v-btn--icon {
  height: 20px !important;
  width: 20px !important;
}
</style>
