<template>
  <v-container v-if="$root.currentUser"
			   class="py-12">

    <!---------------------------------->
    <!-- EXAMENSELECTIVITAT PLUS      -->
    <!---------------------------------->
    <v-card v-if="false" elevation="0" class="rounded-lg mb-12" color="amber lighten-2">
      <v-chip color="primary" class="font-weight-bold" style="position: absolute; top: -15px; left: -10px">
        <span>Examenselectivitat</span>
        <span class="amber--text">Plus</span>
      </v-chip>

      <v-list-item class="pt-5 pb-3">
        <div class="d-flex flex-column" style="flex: 1">
          <h2 class="font-weight-bold">Apunts, explicacions pas per pas i més</h2>
          <p style="font-size: 17px !important;" class="mb-0">Troba tots els apunts que necessites
            per
            les PAU, les
            explicacions del problemes explicats pas per pas,
            les solucions dels llibres de text i la contestació dels teus dubtes en menys de 6h.
          </p>
        </div>
        <v-list-item-action style="height: 100%" class="align-center">
          <v-btn class="my-auto" fab elevation="0" color="primary">
            <v-icon>{{mdiArrowRight}}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>


    <!---------------------------------->
    <!-- PROFILE PAGE                 -->
    <!---------------------------------->
    <h1>Benvingut a examenselectivitat, {{ $root.currentUser.value.user_name }}.</h1>
    <my-subjects class="mt-12"></my-subjects>
    <saved-problems
      @onClick="(collection) => $router.push({name: 'CollectionPage', params: {collection_id: collection.collection_id}})"
      class="mt-12"></saved-problems>

    <!--    <saved-exercices></saved-exercices>-->
  </v-container>
</template>

<script>
import {mdiArrowRight} from '/src/assets/mdi.json'
import MySubjects from "./mySubjects/MySubjects";
import SavedProblems from "./collections/SavedProblems";

export default {
  name: "Profile",
  components: {
    MySubjects,
    SavedProblems
  },
  data() {
    return {
      mdiArrowRight: mdiArrowRight
    }
  }
}
</script>

<style scoped>

</style>
